import { ILang } from "../../../../Interfaces/ILang.type";
import { useApp } from "../../Provider/App.context";
import { TextArea } from "../../../UI/InputFields/TextInput/TextArea.view";
import { TextInput } from "../../../UI/InputFields/TextInput/TextInput.view";
import { SelectInput } from "../../../UI/InputFields/Select/SelectInput.view";
import { FC, useMemo } from "react";
import { useProducts } from "../../../DomMapper/DomComponents/Products/Context/ProductsContext.provider";
import { FONT_OPTIONS } from "sitebuilder-common";
import { useRestaurant } from "../../../../Contexts/Restaurant.context";
import { CheckboxInput } from "../../../UI/InputFields/Checkbox/Checkbox.view";
import { useTranslation } from "react-i18next";
import { AppEditorGroup } from "../AppEditorGroup.view";
import { QR_CART_MODE_OPTIONS } from "../../../../Static/QRCartMode.static";
import ColorInput from "../../../UI/InputFields/ColorInput/ColorInput.view";

interface IAppGlobalFormProps {}

export const AppGlobalForm: FC<IAppGlobalFormProps> = (props) => {
    const { forms } = useApp<"global">();
    const { t } = useTranslation<ILang>();
    const { restaurant } = useRestaurant();
    const { categories } = useProducts();
    const { editor, fieldErrors, bulkChange, handleChange } = forms.global;
    const lastIndex = FONT_OPTIONS.length - 1;

    const productOptions = useMemo(() => {
        const products: { id: string; name: string }[] = [];
        for (const category of categories) {
            for (const product of category.products) {
                for (const subproduct of product.subproducts) {
                    let name = `${category.name} > ${product.name}`;
                    if (subproduct.name) name += ` | ${subproduct.name}`;
                    const id = product.id;
                    products.push({ id, name });
                }
            }
        }
        return products.sort((a, b) => {
            if (a.name.includes("Elvitel")) return -1;
            else if (b.name.includes("Elvitel")) return 1;
            return 0;
        });
    }, [categories]);

    return (
        <AppEditorGroup groupKey={"global"} nameKey={"iframe_global"}>
            <TextInput
                errors={fieldErrors}
                id={"displayedName"}
                onChange={handleChange}
                placeholder={restaurant.name}
                value={editor.displayedName}
            />
            <CheckboxInput
                onChange={handleChange}
                id={"isDarkTheme"}
                value={editor.isDarkTheme}
                labelText={"is_dark_theme"}
                errors={fieldErrors}
            />
            <CheckboxInput
                onChange={handleChange}
                labelText={"is_rounded_btn"}
                id={"isRounded"}
                value={editor.isRounded}
                errors={fieldErrors}
            />
            <SelectInput
                noLabel
                id={"fontFamily"}
                className={"font-input full-width"}
                errors={fieldErrors}
                value={editor.fontFamily || FONT_OPTIONS[lastIndex].id}
                options={FONT_OPTIONS}
                onChange={handleChange}
                isClearable
                formatOptionLabel={(option) => (
                    <div className={`font-option ${option.class}`}>
                        {option.name}{" "}
                        <span className="example-text">({t<ILang>("example_text_font")})</span>
                    </div>
                )}
            />
            <SelectInput
                isClearable
                id={"qr_cart_mode"}
                errors={fieldErrors}
                useTranslationOnName
                onChange={handleChange}
                value={editor.qr_cart_mode}
                options={QR_CART_MODE_OPTIONS}
            />
            <TextInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"extraPageLink"}
                placeholder={t<ILang>("example_link")}
                value={editor.extraPageLink}
            />
            <TextInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"extraPageTitlePrimary"}
                value={editor.extraPageTitlePrimary}
            />
            <TextInput
                errors={fieldErrors}
                onChange={handleChange}
                id={"extraPageTitleSecondary"}
                value={editor.extraPageTitleSecondary}
            />
            {/* <TextInput
                isNumeric
                errors={fieldErrors}
                onChange={handleChange}
                id={"takeAwayProductId"}
                value={editor.takeAwayProductId}
            /> */}
            <SelectInput
                options={productOptions}
                id={"takeAwayProductId"}
                onChange={handleChange}
                value={editor.takeAwayProductId}
            />
            <ColorInput
                value={editor.priceColor}
                onChange={handleChange}
                errors={fieldErrors}
                id={"priceColor"}
                withValueSync
                isClearable
            />
            <ColorInput
                value={editor.backgroundColor}
                onChange={handleChange}
                id={"backgroundColor"}
                errors={fieldErrors}
                withValueSync
                isClearable
            />
            <TextArea
                errors={fieldErrors}
                onChange={handleChange}
                id={"extraPageContent"}
                value={editor.extraPageContent}
            />
            <TextArea
                id={"qrScanSVG"}
                errors={fieldErrors}
                onChange={handleChange}
                value={editor.qrScanSVG}
            />
            <CheckboxInput
                onChange={(field, value) => {
                    bulkChange([
                        { field, value },
                        { field: "isStatusCheckOnWithoutLCT", value: false }
                    ]);
                }}
                id={"allowOrderTracking"}
                value={editor.allowOrderTracking}
                errors={fieldErrors}
            />
            <CheckboxInput
                onChange={(field, value) => {
                    bulkChange([
                        { field, value },
                        { field: "allowOrderTracking", value: false }
                    ]);
                }}
                id={"isStatusCheckOnWithoutLCT"}
                value={editor.isStatusCheckOnWithoutLCT}
                errors={fieldErrors}
            />
            <CheckboxInput
                id={"suggest_in_cart"}
                value={editor.suggest_in_cart}
                onChange={handleChange}
                errors={fieldErrors}
            />

            <CheckboxInput
                id={"suggest_on_add"}
                value={editor.suggest_on_add}
                onChange={handleChange}
                errors={fieldErrors}
            />
        </AppEditorGroup>
    );
};
